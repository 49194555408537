html {
    height: 100% !important;
}
#root {
    height: 100% !important;
}
body {
    /* background-color: black; */
    /* background-color: #141828 !important; */
    padding: 0;
    margin: 0;
    height: 100%;
}
div::-webkit-scrollbar {
    width: 5px;
}

/* .kSxvBW {
    width: 100%;
}
.kSxvBW .muicc-colorpicker-button {

    box-shadow: 2px 7px 17px -5px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 2px 7px 17px -5px rgba(0, 0, 0, 1);
    -moz-box-shadow: 2px 7px 17px -5px rgba(0, 0, 0, 1);

    margin: 6px 6px 0 0 !important;
}
.MuiFormControl-root {
    width: 100% !important;
}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 0 !important;
} */

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #726f6f;
    /* background: #232323; */
}

/* Handle */
::-webkit-scrollbar-thumb {
    /* background: #141828; */
    background: #2c2c2c;
    border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.muicc-colorpicker-button {
    margin: 0 !important;
    width: 30;
    height: 30;
}
/* .muicc-colorbox-colorBg {
    width: 100% !important;
    margin-bottom: 10px;
}
.muicc-colorbox-color {
    width: 100% !important;
}

.muicc-colorbox-colorBg {
    width: 100% !important;
    margin-bottom: 10px;
}
.muicc-colorbox-color {
    width: 100% !important;
} */
.react-multiple-carousel__arrow--right {
    right: calc(-0% + 1px);
}
.react-multiple-carousel__arrow--left {
    left: calc(-0% + 1px);
}
.react-multiple-carousel__arrow::before {
    font-size: 16px;
}
.react-multiple-carousel__arrow {
    background: rgba(0, 0, 0, 0);
    min-width: 30px;
    min-height: 30px;
}

/* stripe */

.DemoPickerWrapper {
    padding: 0 12px;
    font-family: "Source Code Pro", monospace;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    background: white;
    margin: 24px 0 48px;
    width: 100%;
}

.DemoPicker {
    font-size: 18px;
    border-radius: 3px;
    background-color: white;
    height: 48px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    border: 0;
    width: 100%;
    color: #6772e5;
    outline: none;
    background: transparent;

    -webkit-appearance: none;
}

.DemoWrapper {
    margin: 0 auto;
    max-width: 500px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.Demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40%;
}

label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
}

button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
}

button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

input,
.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 24px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 1px solid #5baaba;
    outline: 0;
    border-radius: 4px;
    /* background-color: #141828; */
    background-color: #000;
    /* background: white; */
}

input::placeholder {
    /* color: #aab7c4; */
}

input:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

.StripeElement.PaymentRequestButton {
    height: 40px;
}

.heatbeat-animation {
    animation: beat 0.45s infinite alternate;
    transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
    to {
        transform: scale(1.4);
    }
}
